@import "src/assets/sass/var";

.list-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: rgba(182, 182, 182, 0.34);
  padding: 15px 0;
  margin-bottom: 40px;

  &__fields-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    width: 100%;


    &__field-wrap {
      width: calc(100% - 40px);
      min-width: 300px;
      max-width: 700px;
      overflow: hidden;
      padding: 10px 0;


      .label {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        font-weight: 400;

        .field {
          margin-top: 6px;
          border-radius: 5px;
        }

        .radio {
          margin-top: 6px;

          span {
            text-align: start;
            padding-right: 0;
          }
        }

        .select {
          margin-top: 6px;
          border-radius: 5px;
          width: 100%;
          text-align: start;
        }
      }

      .label-range {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 20px;

        .range {
          width: 630px;
          min-width: 300px;
          max-width: 630px;
          margin: 6px 6px 6px 0;
        }
      }
    }
  }

  &__btn-wrap {
    width: calc(100% - 40px);
    min-width: 300px;
    max-width: 700px;
    height: 65px;

    .btn-submit {
      width: 100%;
      height: 37px;
      color: #fff;
      font-weight: bold;
      font-size: 15px;
      background: $general-text-color;
      border: none;
      box-sizing: border-box;
      cursor: pointer;
      margin-top: 24px;
      border-radius: 2px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

}

