@import "src/assets/sass/var";

.admin-page__banners {
  min-height: 200px;

  &__table {
    border: 0;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    &__thead {
      &__tr {
        th {
          padding: 30px 0;
          text-align: center;
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.25px;
          color: #737484;
          border: solid #bebebe;
          border-top: none;
          border-width: 1px 0;
          font-weight: 600;
        }
      }
    }

    &__tbody {
      &__tr {
        .td {
          padding: 20px 10px;
          text-align: center;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.25px;
          color: #2A2A30;
          word-break: break-word;
        }
        .td {
          .picture-link {
            margin-right: 20px;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .edit-link {
            margin-right: 20px;
            cursor: pointer;
            img {
              width: 18px;
              height: 18px;
            }
          }
          .remove-link {
            cursor: pointer;
            @media screen and (min-width: 0px) and (max-width: 400px) {
              margin-right: 20px;
            }
            img {
              width: 18px;
              height: 18px;
            }
          }
        }
        .td-option {
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (min-width: 0px) and (max-width: 500px) {
            flex-direction: column;
          }
        }

        .layout-info {
          text-align: left;
          padding-left: 10px;
          font-weight: 600;
        }
      }
    }
  }
}
.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.02) !important;
}
.ant-modal-content {
  box-shadow: none!important;
}
