@import "src/assets/sass/var";

.banner-form-wrap {
  max-width: 1200px;
  margin: 0 auto;
  grid-area: main;
  min-height: calc(100vh - 342px);

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background: #ffff;
    padding: 40px;
    border-radius: 4px;

    &__title {
      font-weight: 300;
      color: $general-text-color;
      margin-bottom: 30px;
      margin-top: 0;
    }

    &__fields-block{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      &__field-wrap {
        width: 300px;
        height: 65px;
        overflow: hidden;

        .label {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          font-weight: 400;
          color: $second-text-color;
          font-size: 14px;

          .field {
            margin-top: 4px;
            border-radius: 5px;
            width: 100%;
          }
          .radio {
            margin-top: 4px;
            span {
              text-align: start;
              padding-right: 0;
            }
          }
          .select {
            margin-top: 4px;
            border-radius: 5px;
            width: 100%;
            text-align: start;
          }
          .upload {
            margin-top: 4px;
            border-radius: 5px;
            width: 100%;

          }
          .ant-upload-select {
            width: 100%;
            .ant-btn {
              width: 100%;
            }
          }

          .ant-upload-list {
            height: 36px!important;

            overflow: hidden!important;
            .ant-upload-list-item-container {
              transition: none!important;
            }
          }

          .ant-upload-list-item {
            margin-top: 0!important;
            height: 36px!important;
            border-color: #d9d9d9!important;
            position: inherit!important;

            .ant-upload-list-item-thumbnail {
              width: 32px!important;
              height: 32px!important;
            }
            .ant-upload-list-item-name {
              color: $second-text-color!important;
            }
          }

          .ant-tooltip-content {
            background: #db4465!important;
            display: none!important;
          }
        }
      }

      .upload-wrap {
      }

    }

    &__btn-wrap {
      width: 300px;
      height: 65px;

      .btn-submit {
        width: 100%;
        height: 37px;
        color: #fff;
        font-weight: bold;
        font-size: 15px;
        background: $general-text-color;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
        margin-top: 24px;
        border-radius: 2px;
        &:hover {
          opacity: 0.8;
        }
      }
    }

  }
}

