@import "src/assets/sass/var";

.category-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  &__wrap {
    max-width: 1200px;
    width: 1200px;
    min-height: 500px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 800px) and (max-width: 1300px) {
      flex-wrap: wrap;
      margin: 0 10px;
      width: 720px;
      row-gap: 20px;
    }
    @media screen and (min-width: 0px) and (max-width: 799px) {
      flex-wrap: wrap;
      width: 100%;
      margin: 0 10px;
      row-gap: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__category {
      width: 285px;
      float: left;

      @media screen and (min-width: 500px) and (max-width: 799px) {
        width: 80%;
      }
      @media screen and (min-width: 0px) and (max-width: 499px) {
        width: 90%;
      }

      &__header {
        width: 100%;
        height: 60px;
        margin-bottom: 2px;
        border-radius: 5px;
        background: $general-text-color;
        &__top-img {
          width: 60px;
          height: 60px;
          display: block;
          border-right: 2px solid #fff;
          box-sizing: border-box;
          padding-top: 8px;
          float: left;
        }
        &__top-img img {
          width: 50%;
          display: block;
          margin: 0 auto;
          margin-top: 6px;
        }

        &__top-title {
          height: 60px;
          overflow: hidden;
          text-decoration: none;
          padding-top: 20px;
          padding-left: 18px;
          box-sizing: border-box;
          display: block;
          &__name {
            letter-spacing: -0.6px;
            width: 140px;
            height: 19px;
            overflow: hidden;
            display: inline-block;
            float: left;
            color: #fff;
            text-transform: uppercase;
          }
          &__count {
            color: #fff;
            text-transform: uppercase;
          }
        }
      }
      &__list {
        padding: 0;
        margin: 0;
        &__item {
          height: 40px;
          overflow: hidden;
          list-style-type: none;
          margin-bottom: 2px;
          &__link {
            width: 100%;
            height: 40px;
            padding: 0px 30px 12px 30px;
            font-size: 15px;
            color: #fff;
            text-decoration: none;
            display: block;
            box-sizing: border-box;
            line-height: 3;
            background: rgba(9, 59, 100, 0.91);
            border-radius: 5px;

            &:hover {
              background: $general-text-color;
            }
          }
        }
      }

      &__bottom-link {
        width: 100%;
        height: 40px;
        background: #093b64;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #fff;
        text-decoration: none;
        padding: 10px 0;
        box-sizing: border-box;
        border-radius: 5px;
        background: rgba(9, 59, 100, 0.91);
        &:hover {
          background: $general-text-color;
        }

        img {
          width: 14px;
          height: 14px;
          margin-right: 5px;
        }
      }
    }
  }
}
