@import "src/assets/sass/var";

.company-page {
  width: 100%;
  grid-area: main;
  min-height: calc(100vh - 342px);
  display: flex;
  justify-content: space-around;
  align-items: start;
  column-gap: 60px;

  &__wrap {
    display: flex;
    justify-content: center;
    align-items: start;
    column-gap: 60px;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and (min-width: 1000px) and (max-width: 1249px) {
      max-width: 980px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 450px) and (max-width: 999px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
      width: 90%;
    }
    @media screen and (min-width: 0px) and (max-width: 449px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
      margin: 0;
      width: 86%;
    }

    &__desc-block {
      position: relative;
      min-width: 300px;
      width: 700px;
      max-width: 700px;
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: start;
      column-gap: 40px;
      background: #ffff;
      padding: 20px;
      box-shadow: $box-shadow;
      border-radius: 6px;

      @media screen and (min-width: 0px) and (max-width: 799px) {
        flex-direction: column;
        justify-content: center;
        align-items: start;
        row-gap: 20px;
        width: 100%;
      }

      &__comp-logo {
        width: 150px;
        height: 150px;
        min-width: 150px;
        border-radius: 5px;
        padding: 5px;
        background: #464646;
      }

      &__content {
        width: 100%;
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;

        &__title {
          margin-top: 0;
          font-weight: 400;
          font-size: 18px;
          text-transform: uppercase;
          color: $general-text-color;
        }

        .tr {
          height: 26px;
        }
        .td {
          color: $icon-text-color;
          font-size: 17px;
          font-weight: 600;
          vertical-align: top;
        }
        .td-cont {
          color: $second-text-color;
          font-size: 17px;
          padding-left: 10px;
          vertical-align: top;
        }

        &__button {
          text-decoration: none;
          color: #ffff;
          background: $general-text-color;
          border-radius: 5px;
          padding: 10px;
          margin-top: 40px;
          font-size: 16px;
          font-weight: $header-nav-font-weight;
          &:hover {
            background: $second-hover-text-color;
          }
        }
      }

      .preloader {
        display: none;
      }
      .preloader-active {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(102, 102, 102, 0.09);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 1s ease;
        animation-name: headerLane;
        animation-duration: 21s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: steps(1, end);
      }
    }

    &__advertising-block {
      width: 385px;
      height: 320px;
      margin-top: 40px;
      @media screen and (min-width: 0px) and (max-width: 450px) {
        width: 280px;
        height: 220px;
      }

      &__img {
        width: 385px;
        height: 320px;
        @media screen and (min-width: 0px) and (max-width: 450px) {
          width: 280px;
          height: 220px;
        }
      }
    }
  }

}
