@import "src/assets/sass/var";

.impressum {
  max-width: 1200px;
  min-width: 466.1px;
  margin: 0 auto;
  grid-area: main;
  min-height: calc(100vh - 342px);
  @media screen and (min-width: 0px) and (max-width: 450px) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__wrap {
    min-height: 400px;
    margin-top: 20px;
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffff;
    padding: 40px;
    border-radius: 6px;
    box-shadow: $box-shadow;
    @media screen and (min-width: 0px) and (max-width: 450px) {
      width: 76%;
    }

    &__title {
      margin-bottom: 40px;
      margin-top: 0;
      color: $general-text-color;
      text-transform: uppercase;
    }

    &__content {
      p {
        text-align: left;
        span {
          font-weight: 600;
          color: $icon-text-color;
        }
        a {
          color: $general-text-color;
        }
      }
    }

    &__content {
      p {
        text-align: left;
        strong {
          font-weight: 600;
          color: $icon-text-color;
        }
        a {
          color: $general-text-color;
        }
      }
    }
  }

  .preloader {
    display: none;
  }
  .preloader-active {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 83px;
    left: 0;
    background: rgba(102, 102, 102, 0.09);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s ease;
    animation-name: headerLane;
    animation-duration: 21s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps(1, end);
  }
}
