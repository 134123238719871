@import "src/assets/sass/var";

.admin-page {
  max-width: 1200px;
  margin: 0 auto;
  grid-area: main;
  min-height: calc(100vh - 342px);

  @media screen and (min-width: 700px) and (max-width: 999px) {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: start;
  }

  &__wrap {
    width: 1260px;
    max-width: 1200px;
    @media screen and (min-width: 1000px) and (max-width: 1249px) {
      width: 980px;
      max-width: 980px;
    }
    @media screen and (min-width: 0px) and (max-width: 999px) {
      width: 95%;
      padding: 10px;
    }

    &__title {
      color: $general-text-color;
      text-align: left;
      font-size: 26px;
      font-weight: 500;
      @media screen and (min-width: 0px) and (max-width: 400px) {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
      }

      &__link {
        text-decoration: none;
        margin-left: 71px;
        margin-right: 10px;
        color: $icon-text-color;
        font-size: 18px;
        padding: 5px;
        border: 2px solid transparent;
        border-radius: 5px;
        @media screen and (min-width: 0px) and (max-width: 400px) {
          margin-left: 0;
          padding-left: 0;
          margin-top: 20px;
        }
        &:hover {
          border: 2px solid $general-text-color;
        }
        img {
          width: 16px;
          height: 16px;
          margin-bottom: -1px;
        }
      }
    }

    &__menu {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: start;
      align-items: start;
      column-gap: 40px;

      @media screen and (min-width: 1000px) and (max-width: 1249px) {
        column-gap: 20px;
      }
      @media screen and (min-width: 800px) and (max-width: 999px) {
        column-gap: 10px;
      }
      @media screen and (min-width: 0px) and (max-width: 799px) {
        flex-direction: column;
        row-gap: 20px;
        width: 100%;
      }

      &__sidebar {
        @media screen and (min-width: 0px) and (max-width: 799px) {}

        &__nav {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          background: #ffff;
          padding: 10px;
          border-radius: 6px;
          box-shadow: $box-shadow;
          @media screen and (min-width: 0px) and (max-width: 799px) {
            flex-direction: row;
            justify-content: space-between;
            width: 100%;
          }

          &__link {
            text-decoration: none;
            font-size: 18px;
            font-weight: 500;
            color: $second-text-color;
            padding: 5px;

            &:hover {
              color: $general-text-color;
            }
          }
          .active {
            color: $general-text-color;
          }
        }
      }

      &__content {
        width: 100%;
        background: #ffff;
        margin-bottom: 40px;
        border-radius: 6px;
        box-shadow: $box-shadow;
      }
    }
  }
}
