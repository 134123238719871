.App {
  text-align: center;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 82px calc(100% - 342px) 260px;
  gap: 0 0;
  grid-template-areas:
    "header header header"
    "main main main"
    "footer footer footer";


  @media screen and (min-width: 1000px) and (max-width: 1249px) {
    grid-template-rows: 82px calc(100% - 322px) 260px;
  }
  @media screen and (min-width: 800px) and (max-width: 999px) {
    grid-template-rows: 62px calc(100% - 322px) 260px;
  }
  @media screen and (min-width: 0px) and (max-width: 799px) {
    grid-template-rows: 62px calc(100% - 462px) 400px;
  }
}
