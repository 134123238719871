@import "src/assets/sass/var";

.control-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  left: 0;
  height: 100vh;
  min-height: 600px;
  background-image: url("../../../../assets/img/background-default.jpeg");
  background-repeat: no-repeat;
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @media screen and (min-width: 0px) and (max-width: 799px) {
    min-height: 488px;
    height: 100vh;
    max-height: 850px;
  }
  @media screen and (min-width: 0px) and (max-width: 549px) {
    background-image: url("../../../../assets/img/background-mobile.jpeg");
  }
  @media screen and (min-width: 550px) and (max-width: 799px) {
    background-image: url("../../../../assets/img/background-mobile.jpeg");
  }
  @media screen and (min-width: 800px) and (max-width: 999px) {
    background-image: url("../../../../assets/img/background-1250px.jpeg");
    max-height: 750px;
  }
  @media screen and (min-width: 1000px) and (max-width: 1250px) {
    background-image: url("../../../../assets/img/background-1250px.jpeg");
    max-height: 900px;
  }
  @media screen and (min-width: 1251px) and (max-width: 1480px) {
    background-image: url("../../../../assets/img/background-1250px.jpeg");
    max-height: 1100px;
  }
  @media screen and (min-width: 1481px) and (max-width: 1600px) {
    background-image: url("../../../../assets/img/background-1480px.jpeg");
    max-height: 1000px;
  }
  @media screen and (min-width: 1600px) {
    background-image: url("../../../../assets/img/background-1480px.jpeg");
    max-height: 1000px;
    min-height: 680px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    row-gap: 10px;
    max-width: 600px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 140px;
    margin-left: 50%;

    @media screen and (min-width: 1250px) and (max-width: 1700px) {
      margin-left: 60%;
      padding-top: 140px;
      margin-right: 20px;
    }
    @media screen and (min-width: 1000px) and (max-width: 1249px) {
      padding-top: 120px;
      margin-left: 60%;
    }
    @media screen and (min-width: 800px) and (max-width: 999px) {
      padding-top: 140px;
      margin-left: 60%;
    }
    @media screen and (min-width: 0px) and (max-width: 799px) {
      padding-top: 0;
      margin-left: 0;
      align-items: center;
    }

    &__title {
      width: 100%;
      color: $general-text-color;
      font-weight: 700;
      font-size: 38px;
      text-align: left;
      margin-bottom: 20px;
      @media screen and (min-width: 1250px) and (max-width: 1700px) {
        font-size: 30px;
        margin-bottom: 10px;
      }
      @media screen and (min-width: 1000px) and (max-width: 1249px) {
        font-size: 30px;
        margin-bottom: 10px;
      }
      @media screen and (min-width: 800px) and (max-width: 999px) {
        font-size: 24px;
        margin-bottom: 10px;
      }
      @media screen and (min-width: 0px) and (max-width: 799px) {
        font-size: 24px;
        margin-bottom: 10px;
        padding-top: 0;
        margin-left: 0;
        text-align: center;
        display: none;
      }
    }

    &__text {
      width: 100%;
      text-align: left;
      color: $second-text-color;
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      @media screen and (min-width: 1250px) and (max-width: 1700px) {
        font-size: 16px;
        line-height: 26px;
      }
      @media screen and (min-width: 1000px) and (max-width: 1249px) {
        font-size: 16px;
        line-height: 26px;
      }
      @media screen and (min-width: 800px) and (max-width: 999px) {
        font-size: 14px;
        line-height: 20px;
      }
      @media screen and (min-width: 0px) and (max-width: 799px) {
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        display: none;
      }
    }

    &__category-block {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: start;
      flex-wrap: wrap;
      width: 70%;
      margin-top: 20px;
      @media screen and (min-width: 800px) and (max-width: 999px) {
        width: 100%;
        margin-top: 10px;
      }
      @media screen and (min-width: 0px) and (max-width: 799px) {
        width: 90%;
        margin-top: 10px;
        position: absolute;
        bottom: 260px;
      }

      &__item {
        cursor: pointer;
        padding: 10px;
        background: $general-text-color;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background: $second-hover-text-color;
        }
      }

      &__item img {
        width: 30px;
        height: 30px;
        @media screen and (min-width: 800px) and (max-width: 999px) {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  &__search-block {
    width: calc(100vw - 17px);
    background: $transparent-items-color;
    @media screen and (min-width: 1000px) and (max-width: 1249px) {
      width: 100vw;
    }
    @media screen and (min-width: 0px) and (max-width: 999px) {
      width: 100%;
    }
    @media screen and (min-width: 0px) and (max-width: 799px) {
      height: 230px;
      position: absolute;
      bottom: 0;
    }
    @media screen and (min-width: 1250px) {
      width: 100%;
    }

    &__wrap {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 40px;
      height: 100px;
      @media screen and (min-width: 800px) and (max-width: 999px) {
        width: 100%;
      }
      @media screen and (min-width: 0px) and (max-width: 799px) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 230px;
      }


      .field {
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid $home-text-field-color;
        background: transparent;
        border-radius: 0;
        width: 350px;
        caret-color: $home-text-field-color;
        color: $home-text-field-color;
        @media screen and (min-width: 800px) and (max-width: 999px) {
          width: 200px;
        }

        &::placeholder {
          opacity: .8;
          color: $home-text-field-color;
        }

        &:focus {
          outline: none;
          -webkit-box-shadow: none;
        }
      }

      .field-select {
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid $home-text-field-color;
        background: transparent;
        border-radius: 0;
        width: 350px;
        caret-color: $home-text-field-color;
        color: $home-text-field-color;
        @media screen and (min-width: 800px) and (max-width: 999px) {
          width: 200px;
        }
        @media screen and (min-width: 0px) and (max-width: 799px) {
          width: 80%;
        }

        &:focus {
          outline: none;
          -webkit-box-shadow: none;
        }

        .ant-select-selection-search-input {

        }

        .ant-select-selector {
          background: transparent;
          border: none !important;
          outline: none !important;
          -webkit-box-shadow: none !important;
          color: $home-text-field-color;
        }

        .ant-select-arrow {
          color: $home-text-field-color;
        }

        .ant-select-selection-placeholder {
          opacity: .8;
          color: $home-text-field-color;
          text-align: start;
        }

        .ant-select-selection-item {
          color: $home-text-field-color;
          text-align: start;
        }
      }

      .btn-submit {
        width: 150px;
        height: 37px;
        color: #fff;
        font-weight: bold;
        font-size: 15px;
        background: $general-text-color;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 5px;

        @media screen and (min-width: 0px) and (max-width: 799px) {
          width: 80%;
          margin-bottom: 20px;
        }

        &:hover {
          background: $second-hover-text-color;
        }
      }
    }
  }
}

.ant-select-item-option {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  color: $second-text-color !important;
}
