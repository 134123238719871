@import "src/assets/sass/var";

.main-news-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
  @media screen and (min-width: 0px) and (max-width: 799px) {
    padding-top: 10px;
    padding-bottom: 80px;
    position: inherit;
  }

  &__wrap {
    max-width: 1200px;
    width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 60px;
    @media screen and (min-width: 400px) and (max-width: 1300px) {
      flex-wrap: wrap;
      padding: 20px 20px;
      width: 90%;
      row-gap: 20px;
      column-gap: 20px;
    }
    @media screen and (min-width: 0px) and (max-width: 399px) {
      width: 92%;
    }

    &__title {
      margin-bottom: 40px;
      margin-top: 0;
      color: $general-text-color;
      text-transform: uppercase;
    }

    &__news-block {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 40px;
      column-gap: 40px;

      @media screen and (min-width: 400px) and (max-width: 999px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &__item {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        background: #ffff;
        padding: 20px;
        box-shadow: rgb(131 131 131 / 15%) 0px 16px 20px, rgb(131 131 131 / 15%) 0px 0px 20px;

        &__text {
          width: 540px;
          max-width: 540px;
          min-height: 243px;
          @media screen and (min-width: 1000px) and (max-width: 1300px) {
            width: 420px;
            max-width: 420px;
          }
          @media screen and (min-width: 0px) and (max-width: 799px) {
            width: 100%;
            max-width: 100%;
          }
          h3 {
            text-align: start;
            color: #3d3d3d;
          }
          p {
            text-align: start;
            color: $second-text-color;
            line-height: 26px;
          }
        }

      }
    }


  }
}
