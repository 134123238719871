@import "src/assets/sass/var";

.root__header {
  height: 82px;
  padding-left: 40px;
  padding-right: 40px;
  grid-area: header;
  border-bottom: 1px solid rgba(63, 60, 60, 0.15);
  background: transparent;
  position: relative;
  z-index: 1000;

  @media screen and (min-width: 0px) and (max-width: 999px) {
    padding-left: 20px;
    padding-right: 20px;
    height: 62px;
  }
  @media screen and (min-width: 360px) and (max-width: 420px) {
    padding-left: 0;
    padding-right: 0;
    height: 62px;
  }
  @media screen and (min-width: 0px) and (max-width: 359px) {
    padding-left: 0;
    padding-right: 0;
    height: 62px;
  }

  .wrap {
    max-width: 1600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  &__logo-wrap {
    &__logo {
      height: 69px;
      width: 107px;
      @media screen and (min-width: 0px) and (max-width: 999px) {
        height: 50px;
        width: 78px;
      }
      @media screen and (min-width: 0px) and (max-width: 420px) {
        margin-left: 5px;
      }
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (min-width: 1000px) and (max-width: 1249px) {
      justify-content: end;
    }

    &__nav {
      &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (min-width: 0px) and (max-width: 999px) {
          padding-left: 0;
        }

        &__item {
          padding: 14px;
          list-style: none;
          margin-left: 2px;
          margin-right: 2px;

          @media screen and (min-width: 1250px) and (max-width: 1600px) {
            padding: 10px 5px;
            margin: 0 5px;
          }
          @media screen and (min-width: 1000px) and (max-width: 1249px) {
            padding: 5px 5px;
            margin: 0 5px;
          }
          @media screen and (min-width: 0px) and (max-width: 999px) {
            padding: 5px 5px;
            margin: 0 5px;
          }

          &__link {
            text-decoration: none;
            color: $second-text-color;
            font-size: 16px;
            font-weight: $header-nav-font-weight;
            padding: 0.5rem 1rem;
            transition: text-shadow 0.25s ease 0s;
            position: relative;
            cursor: pointer;
            user-select: none;
            white-space: nowrap;

            @media screen and (min-width: 1000px) and (max-width: 1249px) {
              font-size: 14px;
              padding: 0.5rem 0.2rem;
            }
            @media screen and (min-width: 800px) and (max-width: 999px) {
              font-size: 12px;
              padding: 0.5rem 0.2rem;
            }

            &:hover {
              color: $general-text-color;
            }

            &:after {
                content: "";
                position: absolute;
                left: 16px;
                transition: width 0.25s ease 0s;
                block-size: 0.2rem;
                inline-size: 0;
                background-color: $general-text-color;
                inset-block-end: 0;
              @media screen and (min-width: 1000px) and (max-width: 1249px) {
                left: 4px;
              }
              @media screen and (min-width: 800px) and (max-width: 999px) {
                left: 4px;
              }
            }
            &:hover:after {
              inline-size: 30px;
            }
          }

          .active {
            color: $general-text-color;

            &:after {
              content: "";
              position: absolute;
              left: 16px;
              transition: width 0.25s ease 0s;
              block-size: 0.2rem;
              background-color: $general-text-color;
              inset-block-end: 0;
              inline-size: 30px;
              @media screen and (min-width: 1000px) and (max-width: 1249px) {
                left: 4px;
              }
              @media screen and (min-width: 800px) and (max-width: 999px) {
                left: 4px;
              }
            }
          }

          &__tarif-link {
            color: #ffff;
            background: $general-text-color;
            border-radius: 5px;
            padding: 10px;
            font-size: 16px;
            font-weight: $header-nav-font-weight;
            inline-size: 0;
            text-decoration: none;
            @media screen and (min-width: 1000px) and (max-width: 1249px) {
              font-size: 14px;
            }
            @media screen and (min-width: 366px) and (max-width: 999px) {
              font-size: 14px;
              padding: 10px 10px;
            }
            @media screen and (min-width: 0px) and (max-width: 365px) {
              font-size: 12px;
              padding: 10px 10px;
            }
            &:hover {
              background: $second-hover-text-color;
              inline-size: 0;
            }
            &:after {
              display: none;
            }
          }

          &__tarif-link.active {
            color: #ffff;
          }
        }

        .mobile500 {
          @media screen and (min-width: 0px) and (max-width: 799px) {
            display: none;
          }
        }
      }
    }
  }
}
