@import "src/assets/sass/var";

.main-advertising-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffff;
  padding: 40px 0;
  @media screen and (min-width: 0px) and (max-width: 799px) {
    padding: 0;
  }

  &__title {
    margin-bottom: 40px;
    color: $general-text-color;
    text-transform: uppercase;
  }
  &__wrap {
    max-width: 1200px;
    width: 1200px;
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 20px;
    flex-wrap: wrap;
    @media screen and (min-width: 441px) and (max-width: 1300px) {
      flex-wrap: wrap;
      padding: 20px 20px;
      width: 85%;
      row-gap: 20px;
      column-gap: 20px;
    }
    @media screen and (min-width: 0px) and (max-width: 440px) {
      flex-wrap: wrap;
      padding: 20px 20px;
      width: 80%;
      row-gap: 20px;
      column-gap: 20px;
      flex-direction: column;
      justify-content: start;
      align-items: center;
    }

    &__img {
      width: 200px;
      height: 170px;
    }
  }
}
