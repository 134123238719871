.company-list-page {
  max-width: 1200px;
  margin: 0 auto;
  grid-area: main;
  display: flex;
  justify-content: space-around;
  align-items: start;
  column-gap: 60px;
  min-height: calc(100vh - 82px);

  @media screen and (min-width: 0px) and (max-width: 1249px) {
    flex-wrap: wrap;
    margin: 10px 10px;
    width: 100%;
    row-gap: 20px;
    column-gap: 20px;
  }
  @media screen and (min-width: 0px) and (max-width: 799px) {
   width: 100%;
    margin: 0;
  }

  &__list {
    min-width: 300px;
    width: 700px;
    max-width: 700px;
    padding-bottom: 40px;

    @media screen and (min-width: 1000px) and (max-width: 1249px) {
      width: 500px;
      max-width: 500px;
    }
    @media screen and (min-width: 0px) and (max-width: 799px) {
      width: 94%;
      max-width: 94%;
    }

    .empty-data {
      margin-top: 150px;
    }
  }

  &__advertising-block {
    width: 385px;
    height: 320px;
    margin-top: 70px;
    @media screen and (min-width: 0px) and (max-width: 400px) {
      width: 267px;
      height: 210px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__img {
      width: 385px;
      height: 320px;
      @media screen and (min-width: 1000px) and (max-width: 1249px) {
        width: 280px;
        height: 220px;
      }
      @media screen and (min-width: 0px) and (max-width: 400px) {
        width: 267px;
        height: 210px;
      }
    }
  }

  .preloader {
    display: none;
  }
  .preloader-active {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 83px;
    left: 0;
    background: rgba(102, 102, 102, 0.09);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 1s ease;
    animation-name: headerLane;
    animation-duration: 21s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: steps(1, end);
  }
}
