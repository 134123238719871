@import "src/assets/sass/var";

.root__order {
  max-width: 1200px;
  margin: 0 auto;
  grid-area: main;
  min-height: calc(100vh - 342px);

  @media screen and (min-width: 1000px) and (max-width: 1249px) {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 40px;
  }
  @media screen and (min-width: 0px) and (max-width: 799px) {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 0;
  }

  &__wrap {
    margin-top: 20px;
    display: flex;
    margin-bottom: 20px;
    @media screen and (min-width: 800px) and (max-width: 1249px) {
      margin: 20px 20px 0 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 20px;
    }
    @media screen and (min-width: 0px) and (max-width: 799px) {
      margin: 0;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 20px;
    }

    &__left {
      width: 55%;
      height: 100%;
      background: #ffff;
      padding: 40px;
      border-radius: 6px;
      box-shadow: $box-shadow;

      @media screen and (min-width: 1000px) and (max-width: 1149px) {
        width: 70%;
      }
      @media screen and (min-width: 800px) and (max-width: 999px) {
        width: 70%;
      }
      @media screen and (min-width: 450px) and (max-width: 799px) {
        margin-top: 20px;
        width: 70%;
      }
      @media screen and (min-width: 0px) and (max-width: 449px) {
        width: 86%;
        margin-top: 20px;
        padding: 20px;
      }

      &__title-block {
        color: $general-text-color;
        margin-bottom: 24px;
        &__title {
          margin-top: 0;
        }
        &__desc {

        }
      }

      &__order-form {
        @media screen and (min-width: 800px) and (max-width: 1150px) {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &__fields-block {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          column-gap: 10px;
          @media screen and (min-width: 1000px) and (max-width: 1150px) {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
          }

          .category-title {
            margin-bottom: 8px;
            margin-top: 0;
            padding: 0;
            color: $general-text-color;
            font-weight: 600;
            font-size: 14px;
            text-align: left;
          }
          .category {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            column-gap: 10px;
            margin-bottom: 20px;
          }

          &__field-wrap {
            width: 300px;
            height: 65px;
            overflow: hidden;

            @media screen and (min-width: 0px) and (max-width: 999px) {
              width: 100%;
            }

            .label {
              display: flex;
              flex-direction: column;
              justify-content: start;
              align-items: start;
              color: $second-text-color;
              font-size: 14px;

              .field {
                margin-top: 4px;
                border-radius: 5px;
              }

              .radio {
                margin-top: 6px;

                span {
                  text-align: start;
                  padding-right: 0;
                }
              }

              .select {
                margin-top: 6px;
                border-radius: 5px;
                width: 100%;
                text-align: start;
              }
            }
          }
        }

        &__btn-wrap {
          width: 300px;
          height: 65px;
          float: right;
          @media screen and (min-width: 0px) and (max-width: 450px) {
            width: 100%;
          }
          @media screen and (min-width: 0px) and (max-width: 799px) {
            float: none;
            margin: 0 auto;
          }

          .btn-submit {
            width: 100%;
            height: 37px;
            color: #fff;
            font-weight: bold;
            font-size: 15px;
            background: $general-text-color;
            border: none;
            box-sizing: border-box;
            cursor: pointer;
            margin-top: 24px;
            border-radius: 2px;

            &:hover {
              opacity: 0.8;
            }
          }
        }

      }
    }

    &__right {
      width: 35%;
      float: left;
      padding-left: 3%;
      box-sizing: border-box;
      margin-bottom: calc(100vh - 500px);

      @media screen and (min-width: 0px) and (max-width: 999px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      &__banner-small {
        &__link {
          float: left;
          margin-right: 4%;
          margin-bottom: 3%;
          overflow: hidden;
          color: #10a19a;
          @media screen and (min-width: 400px) and (max-width: 999px) {
            margin-bottom: 20px;
          }
          img {
            width: 184px;
            height: 176px;

            @media screen and (min-width: 1000px) and (max-width: 1249px) {
              width: 164px;
              height: 156px;
            }
            @media screen and (min-width: 0px) and (max-width: 450px) {
              width: 164px;
              height: 156px;
            }
          }
        }
      }

      &__banner-big {
        &__link {
          float: left;
          margin-bottom: 3%;
          overflow: hidden;
          color: #10a19a;
          img {
            width: 384px;
            height: 225px;

            @media screen and (min-width: 1000px) and (max-width: 1249px) {
              width: 280px;
              height: 220px;
            }
            @media screen and (min-width: 0px) and (max-width: 450px) {
              width: 280px;
              height: 220px;
            }
          }
        }
      }
    }
  }
}
