@import "src/assets/sass/var";

.admin__view-profile {
  min-height: 200px;

  &__table {
    margin: 8px 40px;

    &__tbody {

      &__tr {

        .td {
          padding: 8px;
          text-align: left;
          color: $second-text-color;
        }

        .td-title {

        }
        .td-value {
          font-weight: 600;
        }
      }
    }
  }
}
