@import "src/assets/sass/var";

.main-text-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  background: linear-gradient(76.83deg, rgb(178, 196, 225) 4.59%, rgb(224, 232, 236) 66.44%);
  padding: 40px 0;

  &__wrap {
    max-width: 1200px;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 60px;

    @media screen and (min-width: 1000px) and (max-width: 1300px) {
      flex-wrap: wrap;
      margin: 10px 10px;
      width: 100%;
      row-gap: 20px;
      column-gap: 20px;
    }

    @media screen and (min-width: 0px) and (max-width: 999px) {
      flex-wrap: wrap;
      margin: 10px 10px;
      width: 100%;
      row-gap: 20px;
      column-gap: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__text-block {
      width: 720px;
      max-width: 720px;
      padding: 40px;
      @media screen and (min-width: 1000px) and (max-width: 1300px) {
        width: 60%;
        max-width: 60%;
      }
      @media screen and (min-width: 0px) and (max-width: 999px) {
        width: 90%;
        max-width: 90%;
        padding: 20px;
      }

      h3 {
        font-size: 24px;
        color: $general-text-color;
        text-align: center;
        margin: 0;
        text-transform: uppercase;
        @media screen and (min-width: 1000px) and (max-width: 1300px) {
          font-size: 20px;
        }
      }
      p {
        color: #505050;
        text-align: left;
        font-size: 18px;
        line-height: 28px;
        @media screen and (min-width: 1000px) and (max-width: 1300px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    &__advertising-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 40px;

      &__item {

        &__img {
          width: 385px;
          height: 320px;
          @media screen and (min-width: 1000px) and (max-width: 1300px) {
            width: 280px;
            height: 220px;
          }

          @media screen and (min-width: 0px) and (max-width: 600px) {
            width: 280px;
            height: 220px;
          }
        }
      }
    }
  }
}
