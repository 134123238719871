@import "src/assets/sass/var";

.root_tariffs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  grid-area: main;
  margin-top: 1px;
  @media screen and (min-width: 800px) and (max-width: 999px) {
    margin-top: 0;
  }

  &__wrap {
    width: 100%;
    max-width: 1200px;
    @media screen and (min-width: 1000px) and (max-width: 1300px) {
      max-width: 100%;
    }
    @media screen and (min-width: 800px) and (max-width: 999px) {
      margin-top: -18px;
    }

    &__tariffs-header {
      padding: 20px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media screen and (min-width: 1000px) and (max-width: 1300px) {
        padding: 20px 10px;
      }

      &__title {
        text-align: center;
        margin-top: 0;
        font-size: 1.5em;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        color: $general-text-color;
      }

      &__tariffs {
        margin-bottom: 25px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        column-gap: 112px;
        row-gap: 40px;
        @media screen and (min-width: 1000px) and (max-width: 1300px) {
          column-gap: 10px;
          row-gap: 20px;
        }
        @media screen and (min-width: 0px) and (max-width: 999px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        &__tariff {
          width: 325px;
          background: #90A4AE;
          padding: 12px 10px;
          padding-bottom: 25px;
          box-sizing: border-box;
          height: 100%;

          &__title {
            text-align: center;
            text-transform: uppercase;
            font-size: 28px;
            color: #fff;
            margin: 5px 0 12px 0;
          }

          &__middle {
            background: #fff;
            width: 100%;
            min-height: 81%;
            max-height: 81%;
            padding: 20px;
            box-sizing: border-box;

            &__desc {
              min-height: 76px;

              span {
                font-size: 13px;
                color: #6b6b6b;
                font-weight: 400;
                display: block;
                max-height: 75px;

                ul {
                  padding: 0 0 20px 15px;

                  li {
                    list-style-type: none;
                    font-size: 16px;
                    position: relative;
                  }

                  li:before {
                    content: " ";
                    display: block;
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    background: #90A4AE;
                    border-radius: 50%;
                    left: -18px;
                    top: 5px;
                  }
                }
              }
            }
          }

          &__price {
            text-align: center;
            color: #fff;
            text-transform: uppercase;
            font-size: 35px;
          }
        }

        // optional classes
        .basicTarif {
          background: #90A4AE;

          .basicTarif_li:before {
            background: #90A4AE;
          }
        }

        .premiumTarif {
          background: #db4465;

          .premiumTarif_li:before {
            background: #db4465;
          }
        }

        .premiumPlusTarif {
          background: #9ecde7;

          .premiumPlusTarif_li:before {
            background: #9ecde7;
          }
        }

      }

      &__registr-btn {
        width: 250px;
        height: 45px;
        margin: 0 auto;
        display: block;
        text-decoration: none;
        text-align: center;
        line-height: 2.8;
        background: $general-text-color;
        border: none;
        box-sizing: border-box;
        color: #fff;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
        border-radius: 2px;
        transition: all 0.3s ease-out;

        &:hover {
          opacity: 0.8;
        }
      }
    }


    &__tariffs_desc {
      width: calc(100% - 20px);
      margin: 0;
      padding: 50px 10px;
      overflow: hidden;
      @media screen and (min-width: 1000px) and (max-width: 1300px) {
        padding: 50px 10px;
      }

      &__img {
        float: right;
        margin-left: 50px;
        margin-right: 0;
      }

      &__title {
        font-size: 24px;
        font-weight: normal;
        color: #333333;
        margin-bottom: 20px;
        margin-top: 0;
      }

      &__text {
        font-size: 14px;

        p {
          font-size: 14px;
        }
      }
    }

    &__tariffs_desc:nth-of-type(odd) .left {
      float: left;
      margin-right: 50px;
      margin-left: 0;
    }

    &__tariffs_desc:nth-of-type(even) {
      box-shadow: 1140px 0 0 0 #ffff, -1140px 0 0 0 #ffff;
      background: #ffff;
    }
  }
}
