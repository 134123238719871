@import "src/assets/sass/var";

.root__registration {
  max-width: 1200px;
  margin: 0 auto;
  grid-area: main;
  min-height: calc(100vh - 82px);
  @media screen and (min-width: 0px) and (max-width: 999px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
  }

  &__registration-form {
    background: #ffff;
    padding: 40px;
    border-radius: 6px;
    margin-top: 60px;
    margin-bottom: 60px;
    width: 720px;
    box-shadow: $box-shadow;
    @media screen and (min-width: 700px) and (max-width: 999px) {
      width: 85%;
    }
    @media screen and (min-width: 450px) and (max-width: 699px) {
      margin-top: 20px;
      width: 80%;
    }
    @media screen and (min-width: 0px) and (max-width: 449px) {
      margin-top: 20px;
      width: 86%;
      padding: 20px;
    }

    &__title {
      font-weight: 300;
      color: $general-text-color;
      margin-bottom: 30px;
      margin-top: 0;
      text-transform: uppercase;
    }


    &__fields-block{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 10px;

      .category-title {
        margin-bottom: 8px;
        padding: 0;
        color: $general-text-color;
        font-weight: 600;
        font-size: 14px;
      }
      .category {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        column-gap: 10px;
        margin-bottom: 20px;
      }

      &__field-wrap {
        width: 300px;
        height: 65px;
        overflow: hidden;
        @media screen and (min-width: 0px) and (max-width: 699px) {
          width: 100%;
        }

        .label {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          color: $second-text-color;
          font-size: 14px;

          .field {
            margin-top: 4px;
            border-radius: 5px;
          }
          .radio {
            margin-top: 4px;
            span {
              text-align: start;
              padding-right: 0;
              color: $second-text-color;
            }
          }
          .select {
            margin-top: 4px;
            border-radius: 5px;
            width: 100%;
            text-align: start;
          }
        }
      }
    }

    &__btn-wrap {
      width: 300px;
      height: 65px;
      @media screen and (min-width: 0px) and (max-width: 449px) {
        width: 100%;
      }

      .btn-submit {
        width: 100%;
        height: 37px;
        color: #fff;
        font-weight: bold;
        font-size: 15px;
        background: $general-text-color;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
        margin-top: 24px;
        border-radius: 2px;
        &:hover {
          opacity: 0.8;
        }
      }
    }

  }
}

