@import "src/assets/sass/var";

.footer {
  width: 100%;
  height: 260px;
  background: #464646;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: footer;
  gap: 20px;
  @media screen and (min-width: 0px) and (max-width: 799px) {
    height: 400px;
  }

  &__top-block {
  }

  &__middle-block {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    border-bottom: 1px solid rgba(180, 180, 180, 0.2);
    padding-bottom: 40px;
    @media screen and (min-width: 1000px) and (max-width: 1249px) {
      width: 80%;
    }
    @media screen and (min-width: 800px) and (max-width: 999px) {
      width: 90%;
    }
    @media screen and (min-width: 0px) and (max-width: 799px) {
      width: 80%;
      flex-direction: column;
      justify-content: start;
      align-items: center;
    }
    @media screen and (min-width: 0px) and (max-width: 499px) {
      padding-bottom: 10px;
    }

    &__logo-wrap {
      @media screen and (min-width: 0px) and (max-width: 799px) {
        width: 100%;
        text-align: left;
        margin-bottom: 20px;
      }
      &__logo {
        height: 90px;
        width: 140px;
        margin-right: 50px;
        @media screen and (min-width: 400px) and (max-width: 999px) {
          height: 50px;
          width: 78px;
        }
        @media screen and (min-width: 0px) and (max-width: 799px) {
          height: 32px;
          width: 50px;
        }
      }
    }

    &__nav {
      display: flex;
      justify-content: space-between;
      align-items: start;
      width: 100%;
      @media screen and (min-width: 0px) and (max-width: 499px) {
        flex-direction: column;
        justify-content: center;
        align-items: start;
        row-gap: 20px;
      }

      &__menu {
        text-align: start;
        margin: 0;
        padding: 0;

        &__list-item {
          list-style: none;
          padding: 5px;

          &__link {
            text-decoration: none;
            color: #b4b4b4;
            font-size: 16px;
            font-weight: 500;
            &:hover {
              color: $general-text-color;
            }

            .img {
              width: 16px;
              height: 16px;
              margin-right: 5px;
              margin-bottom: -3px;
            }
            .registr {
              margin-right: 10px;
            }
          }
        }
      }

      &__category {
        text-align: start;
        margin: 0;
        padding: 0;

        &__list-item {
          list-style: none;
          padding: 5px;

          &__link {
            text-decoration: none;
            color: #b4b4b4;
            font-size: 16px;
            font-weight: 500;
            &:hover {
              color: $general-text-color;;
            }
          }
        }
      }

      &__about-us {
        text-align: start;
        vertical-align: top;
        margin: 0;
        padding: 0;

        &__list-item {
          list-style: none;
          padding: 5px;

          &__link {
            text-decoration: none;
            color: #b4b4b4;
            font-size: 16px;
            font-weight: 500;
            &:hover {
              color: $general-text-color;;
            }
          }
        }
      }
    }
  }

  &__bottom-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    @media screen and (min-width: 0px) and (max-width: 500px) {
      width: 100%;
    }

    &__copyright {
      color: #b4b4b4;
      @media screen and (min-width: 0px) and (max-width: 500px) {
        font-size: 14px;
      }
    }

    &__nav {
      &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__item {
          list-style: none;
          margin-left: 5px;

          &__link {
            text-decoration: none;
            color: #b4b4b4;
          }
        }
      }
    }
  }
}
