@import "src/assets/sass/var";

.root__login {
  max-width: 1200px;
  margin: 0 auto;
  grid-area: main;
  min-height: calc(100vh - 342px);

  &__login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffff;
    padding: 40px;
    border-radius: 6px;
    margin-top: 60px;
    margin-bottom: 60px;
    box-shadow: $box-shadow;
    @media screen and (min-width: 0px) and (max-width: 400px) {
      padding: 20px;
    }

    &__title {
      font-weight: 300;
      color: $general-text-color;
      margin-bottom: 30px;
      margin-top: 0;
    }

    &__fields-block{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
      &__field-wrap {
        width: 300px;
        height: 65px;
        overflow: hidden;

        .label {
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: start;
          font-weight: 400;
          color: $second-text-color;
          font-size: 14px;

          .field {
            margin-top: 4px;
            border-radius: 5px;
          }
          .radio {
            margin-top: 4px;
            span {
              text-align: start;
              padding-right: 0;
            }
          }
          .select {
            margin-top: 4px;
            border-radius: 5px;
            width: 100%;
            text-align: start;
          }
        }
      }
    }

    &__btn-wrap {
      width: 300px;
      height: 65px;

      .btn-submit {
        width: 100%;
        height: 37px;
        color: #fff;
        font-weight: bold;
        font-size: 15px;
        background: $general-text-color;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
        margin-top: 24px;
        border-radius: 2px;
        &:hover {
          opacity: 0.8;
        }
      }
    }

  }
}

