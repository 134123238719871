@import "src/assets/sass/var";

.root_home {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  grid-area: main;
  overflow: hidden;
  position: relative;
  top: 0;
  margin-top: -82px;

  &__title-text-mobile {
    display: none;

    @media screen and (min-width: 0px) and (max-width: 799px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      row-gap: 10px;
      padding: 10px;
    }
    &__title {
      width: 100%;
      font-size: 24px;
      margin-bottom: 10px;
      line-height: 20px;
      text-align: center;
      color: $general-text-color;
      font-weight: 700;
    }
    &__text {
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: $second-text-color;
      font-weight: 500;
    }
  }

  &__advertising_banner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    &__link {
      &__item {
        width: 1200px;
        max-width: 1200px;
        height: 200px;

        @media screen and (min-width: 1000px) and (max-width: 1200px) {
          width: 100%;
        }
        @media screen and (min-width: 450px) and (max-width: 999px) {
          width: 100%;
          height: 130px;
        }
        @media screen and (min-width: 0px) and (max-width: 449px) {
          width: 100%;
          height: 100px;
        }
      }
    }
  }

}
