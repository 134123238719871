.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  width: 100%;
  .button-container {
    display: flex;
    justify-content: space-between;
    min-width: 200px;
    margin: 0 auto;
    .btn-pagination-disabled {
      border: none;
      opacity: 0.5;
      img {
        width: 22px;
        height: 22px;
      }
    }
    .btn-pagination {
      border: none;
      cursor: pointer;

      img {
        width: 22px;
        height: 22px;
      }
    }

    .btn-pagination-to-first {
      border: none;
      cursor: pointer;
      .to_first-arrow {
        margin-right: -15px;
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
    .btn-pagination-to-first-disabled {
      border: none;
      opacity: 0.5;
      .to_first-arrow {
        margin-right: -15px;
      }
      img {
        width: 22px;
        height: 22px;
      }
    }

    .btn-pagination-to-last {
      border: none;
      cursor: pointer;
      .to_last-arrow {
        margin-right: -15px;
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
    .btn-pagination-to-last-disabled {
      border: none;
      opacity: 0.5;
      .to_last-arrow {
        margin-right: -15px;
      }
      img {
        width: 22px;
        height: 22px;
      }
    }

    .page-number {
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.3px;
      color: #2A2A30;
    }
  }
}
