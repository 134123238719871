@import "src/assets/sass/var";

.company-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 137px;
  float: left;
  background: #ffff;
  margin-bottom: 35px;
  border-radius: 3px;
  padding: 23px;
  box-sizing: border-box;
  position: relative;
  box-shadow: $box-shadow;

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__logo-block {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 90px;
      @media screen and (min-width: 0px) and (max-width: 400px) {
        width: 70px;
        height: 70px;
      }
      &__logo {
        float: left;
        width: 90px;
        margin-right: 20px;
        display: block;
        filter: invert(70%);
        @media screen and (min-width: 0px) and (max-width: 400px) {
          width: 70px;
          height: 70px;
        }
      }
    }

    &__content {
      text-decoration: none;
      float: left;
      width: 81%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;

      &__title {
        color: #666;
        font-weight: bold;
        font-size: 19px;
        margin-top: 10px;
        margin-bottom: 5px;
        height: 25px;
        overflow: hidden;
        text-align: left;
      }
      &__address {
        display: flex;
        justify-content: start;
        align-items: center;
        column-gap: 10px;
        color: #666;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
        text-align: left;

        .address {
          text-decoration: underline;
        }
        span {
          img {
            display: inline-block;
            width: 14px;
            height: 14px;
            margin-right: 3px;
            margin-bottom: -1px;
          }
        }
      }
    }
  }

  &__more-desc {
    display: flex;
    justify-content: end;
    align-items: center;
    //color: #10a19a;
    color: #10a19a;
    font-weight: 400;
    font-size: 13px;
    margin-top: 5px;
    width: 100%;

    img {
      width: 13px;
      height: 13px;
      margin-right: 3px;
    }
  }


}
